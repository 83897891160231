<template>
  <div v-if="dialog" ref="loading" class="modal-loading" @click="() => (dialog = !dialog)">
    <Vue3Lottie :animation-data="whiteLoadingJson" :height="185" :width="185" />
  </div>
</template>

<script>
import { Vue3Lottie } from 'vue3-lottie'
import listLoadingJson from '@/styles/json/loading.json'
import winterLoadingJson from '@/styles/json/Lottie_Main_Comp.json'
import whiteLoadingJson from '@/styles/json/Lottie_Main_White.json'

export default {
  name: 'UiLoading',
  components: {
    Vue3Lottie
  },
  mixins: [],
  props: {
    prependIcon: {
      type: String,
      default: '$vuetify-outline'
    },
    title: {
      type: String,
      default: 'Refreshing Application...'
    }
  },
  data() {
    return {
      dialog: false,
      listLoadingJson,
      winterLoadingJson,
      whiteLoadingJson
    }
  },
  computed: {
    loadingCount() {
      return this.$pinia.loading.loadingCnt
    }
  },
  watch: {
    loadingCount(val) {
      if (val > 0) {
        this.dialog = true
      } else {
        setTimeout(() => {
          if (this.loadingCount > 0) {
            console.log('loading close stop', val)
          } else {
            this.dialog = false
          }
        }, 500)
      }
    }
  },
  mounted() {
    // console.log('UICommonModal mounted')
  },
  updated() {
    // console.log('UICommonModal updated')
  },
  unmounted() {
    // console.log('UICommonModal unmounted')
  },
  methods: {}
}
</script>
<style lang="scss"></style>
